import { faEarth, faSave, faServer, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useReducer } from 'react'
import { twMerge } from 'tailwind-merge'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { useNotification } from '~/team_tools/components/NotificationProvider'
import { PasswordInput, TextInput } from '~/team_tools/ui/input/text'
import { Toggle } from '~/team_tools/ui/input/toggle'
import {
    IntegerValidator,
    MaxLengthValidator,
    NumericValidator,
    RangeValidator,
} from '~/team_tools/ui/input/validators'

type ServerType = {
    id: number
    region: string
    host: string
    port: number
    name: string
    fee: number
    max_players: number
    password: string
    status: string
    players_count: number
    distance: number | null
    map_id: number | null
    map_name: string | null
}

type StateType = {
    edited: boolean
    submiting: boolean
    submited: boolean
}

const ACTIONS = {
    UPDATE_FIELD_VALUE: 'updateFieldValue',
    TOGGLE_STATUS: 'toggleStatus',
    SUBMITING: 'submiting',
    SUBMIT: 'submit',
    ERROR: 'error',
}

const reducer = (state: ServerType & StateType, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_FIELD_VALUE:
            return { ...state, [action.field]: action.value, edited: true }
        case ACTIONS.TOGGLE_STATUS:
            return { ...state, status: action.value ? 'active' : 'inactive', edited: true }
        case ACTIONS.SUBMITING:
            return { ...state, submiting: true }
        case ACTIONS.SUBMIT:
            return { ...state, edited: false, submiting: false }
        case ACTIONS.ERROR:
            return { ...action.server, edited: true, submiting: false, submited: false }
        default:
            throw new Error()
    }
}

export const ServerEdit = ({ server }: { server: ServerType }) => {
    const [state, dispatch] = useReducer(reducer, server)

    const updateFieldValue = (field) => (value) => {
        dispatch({ type: ACTIONS.UPDATE_FIELD_VALUE, field, value })
    }

    const { addNotification } = useNotification()

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (state.submiting) return
        dispatch({ type: ACTIONS.SUBMITING })
        try {
            await jsonrpc('gameapi.server.edit', {
                server_id: state.id,
                name: state.name,
                password: state.password,
                fee: state.fee ? Number(state.fee) : null,
                max_players: state.max_players ? Number(state.max_players) : null,
                map_id: state.map_id ? Number(state.map_id) : null,
                status: state.status,
            })
            dispatch({ type: ACTIONS.SUBMIT })
            addNotification(`Server ${state.name} updated`, 'success')
        } catch (err) {
            addNotification(err.error.message, 'error')
            dispatch({ type: ACTIONS.ERROR })
        }
    }

    return (
        <form
            autoComplete='off'
            className='grid grid-cols-[1fr_1fr_0.5fr_0.5fr_0.5fr_max-content] gap-2 items-end'
            onSubmit={handleSubmit}
        >
            <div className='col-span-6 flex flex-row gap-x-2 items-center text-xs tracking-widest text-white'>
                <Toggle
                    defaultChecked={state.status === 'active'}
                    onChange={(value) => dispatch({ type: ACTIONS.TOGGLE_STATUS, value })}
                />
                <FontAwesomeIcon icon={faEarth} />[{server.id}] {server.region}
                <FontAwesomeIcon icon={faServer} />
                {server.host}:{state.port}
            </div>
            <div className='col-span-6 text-xs flex flex-row items-center gap-x-2'>
                <FontAwesomeIcon icon={faUser} />
                {state.players_count}
            </div>
            <TextInput
                placeholder='Server name'
                label='Name'
                defaultValue={state.name}
                validators={[MaxLengthValidator(64)]}
                onChange={updateFieldValue('name')}
            />
            <PasswordInput
                placeholder='Password'
                defaultValue={state.password}
                validators={[MaxLengthValidator(64)]}
                onChange={updateFieldValue('password')}
            />
            <TextInput
                placeholder='0.00'
                label='Spawn Fee'
                defaultValue={state.fee}
                validators={[NumericValidator({ decimalPlaces: 2 }), RangeValidator({ min: 0, max: 100 })]}
                onChange={updateFieldValue('fee')}
            />
            <TextInput
                placeholder='0-100'
                label='Max Players'
                defaultValue={state.max_players}
                validators={[IntegerValidator, RangeValidator({ min: 0, max: 100 })]}
                onChange={updateFieldValue('max_players')}
            />
            <label className='flex flex-col items-start gap-y-1 text-sm'>
                <span className='text-xs font-bold text-white/50'>Map</span>
                <select
                    className={twMerge(
                        'text-white bg-transparent border border-white/30 rounded py-1 px-2 outline-none'
                    )}
                    value={state.map_id}
                    onChange={(e) => updateFieldValue('map_id')(e.target.value)}
                >
                    <option value='1'>MilitaryBase_v2</option>
                    <option value='2'>TheHangar</option>
                    <option value='3'>TheCage</option>
                </select>
            </label>
            <button
                disabled={!state.edited}
                className='w-8 text-sm py-1 px-2 rounded self-end border border-transparent hover:border-white/30 text-amber-500 disabled:opacity-0'
            >
                <FontAwesomeIcon icon={state.submiting ? faSpinner : faSave} spin={state.submiting} />
            </button>
        </form>
    )
}
