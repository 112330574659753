export default {
    magiceden: 'https://magiceden.io/creators/br1_metaverse',
    fractal: 'https://www.fractal.is/br1',
    opensea: 'https://opensea.io/collection/br1-metaverse',
    twitter: 'https://twitter.com/BR1INFINITE',
    twitch: 'https://www.twitch.tv/br1metaverse',
    discord: 'https://discord.gg/ready',
    tiktok: 'https://www.tiktok.com/@br1infinite',
    youtube: 'https://www.youtube.com/channel/UC8Um7OdyOPzpjdhhIpkCT9g',
    instagram: 'https://www.instagram.com/br1infinite/',
    cardinal: 'https://www.cardinal.so',
    ludex: 'https://www.ludex.gg',
    goat_ventures: 'https://goat.ventures/',
    sixthman_ventures: 'https://www.6thman.ventures/',
    justinkan: 'https://justinkan.com/',
    solana_ventures: 'https://solana.ventures/',
    bigbrain_holdings: 'https://www.bigbrain.holdings/',
    krafton: 'https://krafton.com',
    sino: 'https://www.sinoglobalcapital.com/',
    shima: 'https://shima.capital/',
    solana_fm: (token) => `https://solana.fm/address/${token.mint}`,
    solscan: (token) => `https://solscan.io/account/${token.mint}`,
    solana_explorer: (token) => `https://explorer.solana.com/address/${token.mint}`,
    magiceden_nft: (token) => `https://magiceden.io/item-details/${token.mint}`,
    fractal_nft: (token) => {
        const collectionIds = {
            ape: '5139174843744256',
            droid: '1d815cc0-81b1-42c5-82e7-0a432b088539',
        }
        return `https://www.fractal.is/br1/${collectionIds[token.operative_type]}/${token.mint}`
    },
    opensea_nft: (token) => `https://opensea.io/assets/solana/${token.mint}`,
    media_kit: 'https://drive.google.com/drive/folders/1FOVkx7m50yAKG9qEm2EfdSLyx09rKiUx',
}
