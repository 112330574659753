import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useCrsfToken } from '~/hooks/useCrsfToken'
import { useNotification } from '~/team_tools/components/NotificationProvider'
import { Toggle } from '~/team_tools/ui/input/toggle'

const API_ENDPOINT = '/team-tools/api/server/config'

export const ServerConfig = ({ serverId, config }) => {
    const csrf = useCrsfToken()
    const notify = useNotification()
    const [state, setState] = useState(config)
    const [edited, setEdited] = useState(false)
    const fields = ['min_sessions', 'max_sessions', 'min_kills', 'max_kills']

    const setField = (field, value) => {
        const re = /^[1-9][0-9]{0,9}$/
        const MAX_INT = 2147483647
        if (value === '' || (re.test(value) && Number.parseInt(value) <= MAX_INT)) {
            setState((state) => ({ ...state, [field]: value === '' ? null : Number.parseInt(value) }))
            setEdited(true)
        }
    }

    const setToggle = (value) => {
        setState((state) => ({ ...state, check_requirements: value }))
        setEdited(true)
    }

    const formSave = (e) => {
        e.preventDefault()

        fetch(API_ENDPOINT, {
            method: 'PUT',
            headers: {
                'X-CSRFToken': csrf,
            },
            body: JSON.stringify({ server_id: serverId, ...state }),
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Failed to update config')
                }
                notify.addNotification('Config updated', 'success')
                setEdited(false)
            })
            .catch((e) => {
                notify.addNotification(e.message, 'error')
            })
    }

    return (
        <form onSubmit={formSave} className='flex flex-col gap-2 items-start text-xs'>
            <div className='flex gap-2'>
                <Toggle defaultChecked={state.check_requirements} onChange={setToggle} />
                <span>Check requirements</span>
            </div>
            <div className='flex gap-2 items-end text-left'>
                {fields.map((field) => (
                    <label key={field} className='flex flex-col gap-1'>
                        <span className='font-black text-white/50'>{field.replace('_', ' ')}</span>
                        <input
                            type='text'
                            className='bg-inherit text-inherit p-1 border border-white/20 rounded focus:outline-none focus:border-white/50 text-sm'
                            value={state[field] ?? ''}
                            onChange={(e) => setField(field, e.target.value)}
                        />
                    </label>
                ))}
                {edited && (
                    <button
                        type='submit'
                        className='text-sm py-1 px-2 border border-transparent hover:border-white/20 rounded text-amber-500'
                    >
                        <FontAwesomeIcon icon={faSave} />
                    </button>
                )}
            </div>
        </form>
    )
}
