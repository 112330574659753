import { Fragment, useEffect, useState } from 'react'
import { ServerEdit } from './server_edit'
import { ServerConfig } from './ServerConfig'

export const Servers = () => {
    const [servers, setServers] = useState([])

    const getServers = async () => {
        const response = await fetch('/team-tools/api/servers')
        const servers = await response.json()
        setServers(servers)
    }

    useEffect(() => {
        getServers()
    }, [])

    return (
        <div className='flex flex-col gap-10'>
            {servers.map((server) => (
                <Fragment key={server.id}>
                    <ServerEdit server={server} />
                    <ServerConfig serverId={server.id} config={server.config} />
                    <hr className='border-white/10' />
                </Fragment>
            ))}
        </div>
    )
}
