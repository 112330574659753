{
    "auth": {
        "activate_your_account": "ACTIVATE YOUR ACCOUNT",
        "activation_email_sent": "Activation email sent!",
        "already_have_account": "ALREADY HAVE AN ACCOUNT",
        "confirm_password": "CONFIRM PASSWORD",
        "do_you_remember_it": "DO YOU REMEMBER IT",
        "enter_code": "ENTER CODE",
        "forgot_password": "FORGOT PASSWORD",
        "have_not_account": "HAVEN’T CREATED AN ACCOUNT YET",
        "layout_header": "SPAWN. KILL. EARN. REPEAT.",
        "login": "LOGIN",
        "login_header": "LOG IN TO START EARNING TODAY",
        "password": "PASSWORD",
        "password_reset_link_sent": "Password reset link sent to email",
        "resend_code": "RESEND CODE",
        "reset_password": "RESET PASSWORD",
        "signup": "SIGNUP",
        "signup_header": "SIGN UP TO START EARNING TODAY",
        "username": "USERNAME",
        "verify_your_email": "VERIFY YOUR EMAIL ADDRESS BY ENTERING THE 4 DIGIT CODE SENT TO"
    },
    "common": {
        "enter": "ENTER",
        "reset": "RESET"
    },
    "download": {
        "beta": {
            "discord_button": "message us",
            "email_button": "send us an email",
            "header": "BECOME A BETA TESTER",
            "header2": "TO START EARNING",
            "tweet_button": "tweet at us"
        },
        "header": "WELCOME TO BR1: INFINITE",
        "header2": "BETA TESTER"
    },
    "footer": {
        "about": {
            "game_mechanics": "GAME MECHANICS",
            "game_modes": "GAME MODES",
            "header": "ABOUT BR1",
            "news": "FINANCING NEWS",
            "partners_and_investors": "PARTNERS &\nINVESTORS",
            "trailers": "TRAILERS"
        },
        "sign_up": "SIGN UP",
        "social": {
            "header": "JOIN OUR COMMUNITY"
        }
    },
    "landing": {
        "as_seen_on": "AS SEEN ON",
        "download_now": "DOWNLOAD BETA NOW",
        "game_modes": {
            "free_for_all": "Free-for-All is a risk-free mode where players can enter the game without any entry fee. Always free to play, so you can hone in on your skills. Earn rewards, experience points, and funds by completing various Missions within Free-for-All.",
            "infinite": "BR1: Infinite is the world's first first pay-to-spawn, kill-to-earn shooter. Pay to spawn and for every player you kill, earn their spawn fee (all paid & measured in USD). Risk modes range from $0.10-$1 per spawn/kill."
        },
        "logo": {
            "header": "KILL TO EARN IN THE WORLD'S FIRST",
            "subheader": "REAL MONEY EXTRACTION SHOOTER"
        },
        "news": "FINANCING NEWS",
        "operatives": {
            "apes": "APES",
            "apes_description": "Originally conceived as science projects aimed at enhancing physical capabilities, Apes evolved beyond their initial roles as manual labourers and workers, making them fascinating and formidable participants in the BR1 arena.",
            "droids": "DROIDS",
            "droids_description": "Remote-controlled Droids are often used in hazardous environments, carrying out tasks too dangerous for BIOLOGICAL intervention. Their unique combination of mechanical precision and advanced algorithms makes them formidable opponents in the arena.",
            "header": "OPERATIVES",
            "humans": "HUMANS"
        },
        "partners_and_investors": "PARTNERS & INVESTORS",
        "tabs": {
            "early_access": {
                "header": "Play the beta",
                "text": "You’re still early! Try the BR1: INFINITE Beta for free today."
            },
            "infinite_royal": {
                "header": "NO START. NO FINISH.",
                "text_1": "Infinite Royale (noun):",
                "text_2": "An endless game mode with no timer.",
                "text_3": "Limitless action. All intensity."
            },
            "kill_to_earn": {
                "header": "SPAWN. KILL. EARN. REPEAT.",
                "text": "Dive into thrilling risk-reward dynamics. Pay-to-Spawn, Kill-to-Earn fuels intense, high-stakes action where strategy meets adrenaline."
            },
            "leaderboard": {
                "header": "CLIMB THE RANKS",
                "text": "Kill to earn your way to the top of the leaderboards."
            }
        },
        "trailers": {
            "ape": "APE CINEMATIC TRAILER",
            "droid": "DROID CINEMATIC TRAILER",
            "hide": "HIDE",
            "playing": "CURRENTLY PLAYING",
            "show": "SHOW"
        }
    },
    "menu": {
        "login": "LOGIN",
        "signup": "SIGNUP"
    }
}
